@media only screen and (max-width: 499px) {
    .counterBlock{
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        width: 50%;
        margin-left: auto;
        margin-right: auto;
        
      }
    
      .countdown{
        width: 96%;
        text-align: center;
        color: black;
        padding: 30px;
        font-size: 25px;
      
      }
    
    .days{
        background-color: rgb(214, 214, 214);
        padding-top: 5px;
        padding-bottom: 5px;
        padding-right: 15px;
        padding-left: 15px;
        border-radius: 5px;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        margin-right: 5px;
        margin-left: 5px;
    
    }
    .sec{
        animation: 1s 0.875s infinite timer_beat;
        background-color: rgb(214, 214, 214);
        padding-top: 5px;
        padding-bottom: 5px;
        padding-right: 15px;
        padding-left: 15px;
        border-radius: 5px;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        margin-left: 5px;
    
    }
      
    @keyframes timer_beat {
      0% {
        transform: scale(1.125); 
      }
      50% {
        transform: none; }
       }
}

@media only screen and (min-width: 500px) {
    .counterBlock{
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        width: 50%;
        margin-left: auto;
        margin-right: auto;
        
      }
    
      .countdown{
        width: 65%;
        text-align: center;
        color: black;
        padding: 30px;
        font-size: 25px;
      
      }
    
    .days{
        background-color: rgb(214, 214, 214);
        padding-top: 5px;
        padding-bottom: 5px;
        padding-right: 15px;
        padding-left: 15px;
        border-radius: 5px;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        margin-right: 5px;
        margin-left: 5px;
    
    }
    .sec{
        animation: 1s 0.875s infinite timer_beat;
        background-color: rgb(214, 214, 214);
        padding-top: 5px;
        padding-bottom: 5px;
        padding-right: 15px;
        padding-left: 15px;
        border-radius: 5px;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        margin-left: 5px;
    
    }
      
    @keyframes timer_beat {
      0% {
        transform: scale(1.125); 
      }
      50% {
        transform: none; }
       }
}

@media only screen and (min-width: 768px) {
    .counterBlock{
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        width: 50%;
        margin-left: auto;
        margin-right: auto;
        
      }
    
      .countdown{
        width: 50%;
        text-align: center;
        color: black;
        padding: 30px;
        font-size: 35px;
      
      }
    
    .days{
        background-color: rgb(214, 214, 214);
        padding-top: 5px;
        padding-bottom: 5px;
        padding-right: 15px;
        padding-left: 15px;
        border-radius: 5px;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        margin-right: 5px;
        margin-left: 5px;
    
    }
    .sec{
        animation: 1s 0.875s infinite timer_beat;
        background-color: rgb(214, 214, 214);
        padding-top: 5px;
        padding-bottom: 5px;
        padding-right: 15px;
        padding-left: 15px;
        border-radius: 5px;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        margin-left: 5px;
    
    }
      
    @keyframes timer_beat {
      0% {
        transform: scale(1.125); 
      }
      50% {
        transform: none; }
       }
}

@media only screen and (min-width: 992px) {
    .counterBlock{
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        width: 50%;
        margin-left: auto;
        margin-right: auto;
        
      }
    
      .countdown{
        width: 50%;
        text-align: center;
        color: black;
        padding: 30px;
        font-size: 35px;
      
      }
    
    .days{
        background-color: rgb(214, 214, 214);
        padding-top: 5px;
        padding-bottom: 5px;
        padding-right: 15px;
        padding-left: 15px;
        border-radius: 5px;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        margin-right: 5px;
        margin-left: 5px;
    
    }
    .sec{
        animation: 1s 0.875s infinite timer_beat;
        background-color: rgb(214, 214, 214);
        padding-top: 5px;
        padding-bottom: 5px;
        padding-right: 15px;
        padding-left: 15px;
        border-radius: 5px;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        margin-left: 5px;
    
    }
      
    @keyframes timer_beat {
      0% {
        transform: scale(1.125); 
      }
      50% {
        transform: none; }
       }
}

@media only screen and (min-width: 1200px) {
    .counterBlock{
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        width: 50%;
        margin-left: auto;
        margin-right: auto;
        
      }
    
      .countdown{
        width: 50%;
        text-align: center;
        color: black;
        padding: 30px;
        font-size: 35px;
      
      }
    
    .days{
        background-color: rgb(214, 214, 214);
        padding-top: 5px;
        padding-bottom: 5px;
        padding-right: 15px;
        padding-left: 15px;
        border-radius: 5px;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        margin-right: 5px;
        margin-left: 5px;
    
    }
    .sec{
        animation: 1s 0.875s infinite timer_beat;
        background-color: rgb(214, 214, 214);
        padding-top: 5px;
        padding-bottom: 5px;
        padding-right: 15px;
        padding-left: 15px;
        border-radius: 5px;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        margin-left: 5px;
    
    }
      
    @keyframes timer_beat {
      0% {
        transform: scale(1.125); 
      }
      50% {
        transform: none; }
       }
}