@media only screen and (max-width: 499px) {
 
  @keyframes scroll {
    0% {
      transform: translateX(0);
   }
    100% {
      transform: translateX(calc(-250px * 7));
   }
  }
  .slider {
    background: white;
    box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125);
    height: 125px;
    margin-bottom: 20px;
    width: 100%;
    margin: auto;
    overflow: hidden;
    position: relative;
  }
  .slider::before, .slider::after {
    content: "";
    height: 125px;
    position: absolute;
    width: 125px;
    z-index: 2;
  }
  .slider::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }
  .slider::before {
    left: 0;
    top: 0;
  }
  .slider .slide-track {
    animation: scroll 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }
  .slider .slide {
    height: 125px;
    width: 125px;
  }
  .welcome{
      font-size: 45px;
      font-family: 'Roboto', sans-serif;
      text-align: center;
      padding: 50px;
      color: white;
      background-color: rgb(22, 22, 22);
  }

}


@media only screen and (min-width: 500px) {
  
  @keyframes scroll {
    0% {
      transform: translateX(0);
   }
    100% {
      transform: translateX(calc(-250px * 7));
   }
  }
  .slider {
    background: white;
    box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125);
    height: 125px;
    margin-bottom: 20px;
    width: 100%;
    margin: auto;
    overflow: hidden;
    position: relative;
  }
  .slider::before, .slider::after {
    content: "";
    height: 125px;
    position: absolute;
    width: 125px;
    z-index: 2;
  }
  .slider::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }
  .slider::before {
    left: 0;
    top: 0;
  }
  .slider .slide-track {
    animation: scroll 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }
  .slider .slide {
    height: 125px;
    width: 125px;
  }
  .welcome{
      font-size: 45px;
      font-family: 'Roboto', sans-serif;
      text-align: center;
      padding: 50px;
      color: white;
      background-color: rgb(22, 22, 22);
  }
} 

@media only screen and (min-width: 768px) {
  
  @keyframes scroll {
    0% {
      transform: translateX(0);
   }
    100% {
      transform: translateX(calc(-250px * 7));
   }
  }
  .slider {
    background: white;
    box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125);
    height: 150px;
    margin-bottom: 20px;
    width: 100%;
    margin: auto;
    overflow: hidden;
    position: relative;
  }
  .slider::before, .slider::after {
    content: "";
    height: 150px;
    position: absolute;
    width: 150px;
    z-index: 2;
  }
  .slider::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }
  .slider::before {
    left: 0;
    top: 0;
  }
  .slider .slide-track {
    animation: scroll 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }
  .slider .slide {
    height: 150px;
    width: 150px;
  }
  .welcome{
      font-size: 45px;
      font-family: 'Roboto', sans-serif;
      text-align: center;
      padding: 50px;
      color: white;
      background-color: rgb(22, 22, 22);
  }
} 

@media only screen and (min-width: 992px) {
  
  @keyframes scroll {
    0% {
      transform: translateX(0);
   }
    100% {
      transform: translateX(calc(-250px * 7));
   }
  }
  .slider {
    background: white;
    box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125);
    height: 200px;
    margin-bottom: 20px;
    width: 100%;
    margin: auto;
    overflow: hidden;
    position: relative;
  }
  .slider::before, .slider::after {
    content: "";
    height: 200px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }
  .slider::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }
  .slider::before {
    left: 0;
    top: 0;
  }
  .slider .slide-track {
    animation: scroll 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }
  .slider .slide {
    height: 200px;
    width: 200px;
  }
  .welcome{
      font-size: 45px;
      font-family: 'Roboto', sans-serif;
      text-align: center;
      padding: 50px;
      color: white;
      background-color: rgb(22, 22, 22);
  }
} 

