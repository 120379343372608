*{   
  font-family: "lucida sans unicode"
}

@media only screen and (max-width: 499px) {
  .vidCont{
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
  }

  .videoBg {
    top: 0;
   left: 0px;
   width: 90%;
   box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
   margin-bottom: 20px;
   margin-left: auto;
   margin-right: auto;
   }

  .gameMonkeys{
    display: flex;
    flex-flow: column nowrap; 
    
  
  }
  .gMPic{
    width: 200px;
    height: 200px;
    border-radius: 8px;
    margin-top: 8px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    justify-content: center;
  }
  .mintedAmount{
    margin-left: 3%;
    font-size: 15px;
    font-weight: bold;
    color: white;
    background-color: #3f3f3f;
    border-radius: 5px;
    width: max-content;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;}
  .carouselIMG{
    height:125px;
    width:125px;
  }
  .headerImg{

    width: 100%;
    
      }

  .nftblockWalletConnectedALL{
    width: 100%;
  }   
  
.successfully{
  margin-top: 1%;
  font-size: 20px;
  color: #53c245;
  margin-left: 3%;

}

.loadingContainer{
  margin-left: 3%;
  margin-top: 1%;
}

.errorMessage{
margin-left: 3%;
margin-top: 1%;
font-size: 20px;
color: #ec556c;

} 
.loadingText {
  font-size: 20px;
}

/* loading dots */


.loadingText:after {
  content: '.';
  animation: dots 1s steps(5, end) infinite;}

@keyframes dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  40% {
    color: rgb(151, 151, 151);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  60% {
    text-shadow:
      .25em 0 0 rgb(124, 124, 124),
      .5em 0 0 rgba(0,0,0,0);}
  80%, 100% {
    text-shadow:
      .25em 0 0 rgb(121, 121, 121),
      .5em 0 0 rgb(121, 121, 121);}}

.btnfos-0-3{
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 30px;
  font-family: "Poppins";
  border-radius: 5px;
  border-style: outset;
  border-color: white;
  border-width: 2px;
  font-family: "Poppins";
  cursor: pointer;

}

.btnfos-0-3:active {
  background-color: #777777;
  transform: translateY(4px);
}

.btnfos-0-3:hover{
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
}
  
.mintbuttondiv{
  margin-top: 2%;
  margin-left: 3%;

}
.minting_count_button{
  display: flex;
  flex-flow: row nowrap;
  justify-content: left;
  margin-left: 6.5%;
  margin-top: 3%;
}  

.btnfos-0-2 {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border:none;
  border-radius: 5px;
  border-style: outset;
  cursor: pointer;

  
}

.nftamount{
  padding-left: 10px;
  padding-right: 10px;
  font-size: 30px;
  font-family: "Poppins";

}
  
.walletConnect{
  margin-left: 3%;
  margin-top: 3%;
}  

.wallet3{
  padding-top: 25px;
  padding-bottom: 25px;
  width: 250px;
  letter-spacing: 3px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  font-size: 20px;
  font-weight: bolder;
  border-radius: 5px;
  cursor: pointer;
  border-style: none;
  font-family: "Poppins";
  background-color: black;
  color: white;
  box-shadow: 0 5px 15px black;
} 

.wallet3:active {
  background-color: #bbbbbb;
  transform: translateY(4px);
}

.wallet3:hover{
  color: rgb(0, 0, 0);
  background-color: #ececec;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

  .storyB {
    background-color: black;
    width: 15%;
    text-align: left;
    border: none;
    color: white;
    border-radius: 5px;
    margin-right: 0.71%;
    margin-left: 0.71%;
    font-weight: bold;
    font-size: 7px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    
  }

  .storyB2 {
    color: white;
  }

  .storyB:hover{
    color: white;
  }

  .buttonsDown {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }  

  .btnSet {
    background-color: rgb(209, 209, 209);
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    padding-left: 0.71%;
    padding-right: 0.71%;

  }
    
  .bg {
   /* background-image: url(./logo/monkey.jpg);
    background-size: cover;background-repeat: no-repeat;
    background-attachment: fixed;
    width: 100%;
    background-color: white; */
    height: fit-content;
    
    
  
  }

  .pic {
    width: 114px;
    height: 171px;
    position: -webkit-sticky;
    position: sticky;
    bottom: 30px;
    left: 80%;
  }

  .bg2 {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    z-index: -1;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }

 
  .headers{
    background-color: rgba(170, 169, 169, 0);
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 20px;
    padding-top: 20px;
    box-shadow: rgba(0, 0, 0, 0.13) 0px 2px 5px -1px, rgba(0, 0, 0, 0.089) 0px 1px 3px -1px;
    padding-left: 5%;
    padding-right: 5%;
    border-left-width: 0px;
    border-right-width: 0px;
    border-top-width: 0px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: none;
  }
  
  
  
  .title {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .navBar {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    cursor: pointer;
    display: none;
  } 
  
  .navBar div {
    margin-left: 10px;
    margin-right: 10px;
    font-weight: bold;
    color: black;
    padding-top: 15px;
    font-size: 15px;
  }
  
  .navBar div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-weight: bold;
    color: #ee314c;
    padding-top: 15px;
    font-size: 15px;
  }
  
  .rightNav {
    width: 60%;
    display: flex;
    justify-content: flex-end;
  }
  
  .sticky {
    position: fixed;
    top: 0;
    width: 100%;
  }
  
  .sticky + .content {
    padding-top: 60px;
  }
  
  .footerDetails a{
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  
  }
  
  .footerDetails a:hover{
    color: #ffffff;
    
    }
  
     /*................. STORY................... */
  
  .boxWrap2 {
    margin-top: 30px;
  }
  
  .storyHeader {
    font-family: "poppins";
    color: black;
    font-size: 30px;
    font-weight: bolder;
    margin-top: 10px;
  
  }
  
  .storyLeft {
    margin-top: 3%;
    margin-left: 3%;
  }
  
  .storyDetails {
    color: rgb(75, 75, 75);
    font-size: 15px;
    width: 100%;  
    text-align: justify;
  }
  
    /*................. UTILITY................... */
  
  .utilityHeader {
    font-family: "poppins";
    color: black;
    font-size: 30px;
    font-weight: bolder;
  }  
  
  .utilityLeft {
    margin-top: 3%;
    margin-left: 3%;
  }
  
  .utilityDetails {
    color: rgb(0, 0, 0);
    font-size: 15px;
    width: 100%;
    text-align: justify;
  }
  
  .box {
    background-color: rgb(105, 105, 105);
    border-radius: 5px;
    padding: 15px;
    color: white;
  }
  
  .box:hover {
    background-color: black;
    color: white;
  
  }
  
  /*.............. FAQ ..............*/
      
      
  summary {
    font-size: 17px;
    font-weight: 600;
    background-color: rgb(29, 29, 29);
    color: rgb(255, 255, 255);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    border-radius: 0.25rem;
    text-align: left;
    cursor: pointer;
    position: relative;
    
  
  }
  
  
  details > summary::after {
    position: absolute;
    content: "+";
    right: 20px;
  
  }
  details[open] > summary::after {
    position: absolute;
    content: "-";
    right: 20px;
  }
  details > summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary ~ * {
    animation: sweep .5s ease-in-out;
  }
  @keyframes sweep {
    0%    {opacity: 0; margin-top: -10px}
    100%  {opacity: 1; margin-top: 0px}
  }   
  
  .accordin{
  height: 600px;
  width: 100%;
  color: white;
  display: inline-block;
  flex-direction: column;
  margin-left: 3%;
  
  
  }
  
  .faq__content{
  
  color: rgba(0, 0, 0, 0.76);
  padding-bottom: 20px;
  text-align: left;
    
  }
  
  .faqTitle{
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
    font-size: 30px;
    font-weight: bolder;
    color: #000000;  
    font-family: "poppins";   
  
  }
  
  .faqbg{
    
  background-size: cover;
  width:100%;
  text-align:left;
  padding:2px;
  padding-top: 2%;
  padding-bottom: 20px;
  }
  
  /*.............. TEAM ..............*/
  
  .group {
  width: 40%;
  margin-left: 3%;
  }
  
  .teamD {
  display: flex;
  justify-content: left;
  flex-flow: column nowrap;
  }
  
  .teamTitle {
  font-family: "poppins";
  color: black;
  font-size: 30px;
  font-weight: bolder;
  margin-left: 3%;
  margin-bottom: 20px;
  }
  
  .guy1 {
    background-color: #ffffff67;
    padding: 1.5%;
    border-color: rgb(221, 221, 221);
    border-style: solid;
    border-width: 1.5px;
    transition: transform .3s;
    width: fit-content;

  }
  
  .guy1:hover {
    background-color: #ee314c;
    -ms-transform: scale(0.8); /* IE 9 */
    -webkit-transform: scale(0.8); /* Safari 3-8 */
    transform: scale(1.1);
    border: none;
    color: #ffffff;
  
  } 
  
  .guy2 {
    background-color: #ffffff67;
    padding: 1.5%;
    border-color: rgb(221, 221, 221);
    border-style: solid;
    border-width: 1.5px;
    transition: transform .3s;
    width: fit-content;
  
  }
  
  .guy2:hover {
    background-color: #ee314c;
    -ms-transform: scale(0.8); /* IE 9 */
    -webkit-transform: scale(0.8); /* Safari 3-8 */
    transform: scale(1.1);
    border: none;
    color: #ffffff;
  
  } 
  
  .guy3 {
    background-color: #ffffff67;
    padding: 1.5%;
    border-color: rgb(221, 221, 221);
    border-style: solid;
    border-width: 1.5px;  
    transition: transform .3s;
    width: fit-content;

  }
  
  .guy3:hover {
    background-color: #ee314c;
    -ms-transform: scale(0.8); /* IE 9 */
    -webkit-transform: scale(0.8); /* Safari 3-8 */
    transform: scale(1.1);
    border: none;
    color: #ffffff;
  
  } 
  
  .guy4 {
    background-color: #ffffff67;
    padding: 1.5%;
    border-color: rgb(221, 221, 221);
    border-style: solid;
    border-width: 1.5px;
    transition: transform .3s;
    width: fit-content;

  }
  
  .guy4:hover {
    background-color: #ee314c;
    -ms-transform: scale(0.8); /* IE 9 */
    -webkit-transform: scale(0.8); /* Safari 3-8 */
    transform: scale(1.1);
    border: none;
    color: #ffffff;
  
  } 
  
  .guy5 {
    background-color: #ffffff67;
    padding: 1.5%;
    border-color: rgb(221, 221, 221);
    border-style: solid;
    border-width: 1.5px;
    transition: transform .3s;
    width: fit-content;

  }
  
  .guy5:hover {
    background-color: #ee314c;
    -ms-transform: scale(0.8); /* IE 9 */
    -webkit-transform: scale(0.8); /* Safari 3-8 */
    transform: scale(1.1);
    border: none;
    color: #ffffff;
  } 
  
  .man1 {
    background-image: url(./logo/1.png);
    width: 175px;
    height: 175px;
    background-size: cover;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  
  .man2 {
    background-image: url(./logo/2.png);
    width: 175px;
    height: 175px;
    background-size: cover;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .man7 {
    background-image: url(./logo/newMem.jpg);
    width: 175px;
    height: 175px;
    background-size: cover;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  
  .man3 {
    background-image: url(./logo/3.png);
    width: 175px;
    height: 175px;
    background-size: cover;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  
  .man4 {
    background-image: url(./logo/5.png);
    width: 175px;
    height: 175px;
    background-size: cover;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  
  .man5 {
    background-image: url(./logo/6.png);
    width: 175px;
    height: 175px;
    background-size: cover;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  
  
  .man6 {
    background-image: url(./logo/26.png);
    width: 175px;
    height: 175px;
    background-size: cover;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .name1 {
    margin-top: 10%;
    text-align: left;
    font-size: larger;
    font-weight: bold;
  }
  
  .name2 {
    text-align: left;
    font-size: small;
  }
  
  /*.............. SOCIAL ICONS ..............*/
  
  .socialContainer {
    width: 25%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 10px;
  }
  
  /*.............. FOOTER ..............*/
  
  .footer {
    margin-top: 5%;
    margin-left: 3%;
  }
  
  .footerHeader {
    color: black;
    font-family: "Poppins";
    font-size: 30px;
    font-weight: bolder;
  }
  
  .footerP {
    color: rgb(75, 75, 75);
    font-size: 15px;
    width: 100%;
    margin-top: 20px;
    text-align: justify;
  }
  
  .line {
    width: 100%;
    height: 1px;
    background-color: rgb(219, 219, 219);
    margin-top: 15px;
  }
  
  .footerBox1 {
    width: 300px;
    height: 100px;
    border-radius: 5px;
    margin-top: 25px;
    background-image: url(./assets/email_before.jpg);
    background-size: cover;
    border: none;
  }
  
  .footerBox1:hover { 
    background-image: url(./assets/email_after.jpg);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    color: rgb(226, 226, 226);
    cursor: pointer;
  
  }
  
  .footerBox2 {
    width: 300px;
    height: 100px;
    border-radius: 5px;
    margin-top: 25px;
    background-image: url(./assets/instagram_before.jpg);
    background-size: cover;
    border: none;
  }
  
  .footerBox2:hover { 
    background-image: url(./assets/instagram_after.jpg);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    color: rgb(226, 226, 226);
    cursor: pointer;
  
  }
  
  .footerBox3 {
    width: 300px;
    height: 100px;
    border-radius: 5px;
    margin-top: 25px;
    background-image: url(./assets/twitter_before.jpg);
    background-size: cover;
    border: none;
  }
  
  .footerBox3:hover { 
    background-image: url(./assets/twitter_after.jpg);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    color: rgb(226, 226, 226);
    cursor: pointer;
  
  }
  
  .footerBox4 {
    width: 300px;
    height: 100px;
    border-radius: 5px;
    margin-top: 25px;
    background-image: url(./assets/discord_before.jpg);
    background-size: cover;
    border: none;
  }
  
  .footerBox4:hover { 
    background-image: url(./assets/discord_after.jpg);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    color: rgb(226, 226, 226);
    cursor: pointer;
  
  }
  
  .iconsSection1 {
    display: flex;
    flex-flow: column nowrap;
  }
  
  .iconsSection2 {
    display: flex;
    flex-flow: column nowrap;
  }
  
  .footerDetails {
    color: black;
    font-family: "lucida sans unicode";
    font-size: 18px;
    font-weight: bolder;
    width: 300px;
    height: 100px;
    text-align: center;
    padding-left: 50px;
    padding-top: 30px;
  
  
  }
  
  .footerDetails2 {
    color: rgb(75, 75, 75);
    font-size: 12px;
  
  }
  
  .footerDetails:hover {
    color: rgb(226, 226, 226);
  }
  
  .footerDetails p{
  
   font-size: 9px ;
   color: rgb(161, 161, 161);
   margin-top: -3px;
  
  
  }
  
  .footerDetails2:hover {
    color: rgb(201, 201, 201);
  }
  
  }

@media only screen and (min-width: 500px) {
  .vidCont{
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    margin-top: 40px;
  }

  .videoBg {
    top: 0;
   left: 0px;
   width: 80%;
   box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;   margin-right: 20px;
   margin-bottom: 20px;
   }
  
  .gameMonkeys{
    display: flex;
    flex-flow: column nowrap; 
  
  }
  .gMPic{
    width: 200px;
    height: 200px;
    border-radius: 8px;
    margin-top: 8px;
  }
  .mintedAmount{
    margin-left: 3%;
    font-size: 20px;
    font-weight: bold;
    color: white;
    background-color: #3f3f3f;
    border-radius: 5px;
    width: max-content;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;}
  
  .carouselIMG{
    height:125px;
    width:125px;
  }
  .headerImg{

    width: 100%;
    
      }
    
.successfully{
  margin-top: 1%;
  font-size: 20px;
  color: #53c245;
  margin-left: 3%;

}

.loadingContainer{
  margin-left: 3%;
  margin-top: 1%;
}

.errorMessage{
margin-left: 3%;
margin-top: 1%;
font-size: 15px;
color: #ec556c;

} 
.loadingText {
  font-size: 20px;
}

/* loading dots */


.loadingText:after {
  content: '.';
  animation: dots 1s steps(5, end) infinite;}

@keyframes dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  40% {
    color: rgb(151, 151, 151);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  60% {
    text-shadow:
      .25em 0 0 rgb(124, 124, 124),
      .5em 0 0 rgba(0,0,0,0);}
  80%, 100% {
    text-shadow:
      .25em 0 0 rgb(121, 121, 121),
      .5em 0 0 rgb(121, 121, 121);}}

.btnfos-0-3{
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 30px;
  font-family: "Poppins";
  border-radius: 5px;
  border-style: outset;
  border-color: white;
  border-width: 2px;
  font-family: "Poppins";
  cursor: pointer;

}

.btnfos-0-3:active {
  background-color: #777777;
  transform: translateY(4px);
}

.btnfos-0-3:hover{
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
}
  
.mintbuttondiv{
  margin-top: 2%;
  margin-left: 3%;

}
.minting_count_button{
  display: flex;
  flex-flow: row nowrap;
  justify-content: left;
  margin-left: 6.5%;
  margin-top: 3%;
}  

.btnfos-0-2 {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border:none;
  border-radius: 5px;
  border-style: outset;
  cursor: pointer;

  
}

.nftamount{
  padding-left: 10px;
  padding-right: 10px;
  font-size: 30px;
  font-family: "Poppins";

}
  
.walletConnect{
  margin-left: 3%;
  margin-top: 3%;
}  

.wallet3{
  padding-top: 25px;
  padding-bottom: 25px;
  width: 250px;
  letter-spacing: 3px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  font-size: 25px;
  font-weight: bolder;
  border-radius: 5px;
  cursor: pointer;
  border-style: none;
  font-family: "Poppins";
  background-color: black;
  color: white;
  box-shadow: 0 5px 15px black;
} 

.wallet3:active {
  background-color: #bbbbbb;
  transform: translateY(4px);
}

.wallet3:hover{
  color: rgb(0, 0, 0);
  background-color: #ececec;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

  .storyB {
    background-color: black;
    width: 15%;
    text-align: left;
    border: none;
    color: white;
    border-radius: 5px;
    margin-right: 0.71%;
    margin-left: 0.71%;
    font-weight: bold;
    font-size: smaller;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding-top: 15px;
    padding-left: 3px;
  }
  .storyB2 {
    color: white;

  }

  .storyB:hover{
    color: white;
  }

  .buttonsDown {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }  

  .btnSet {
    background-color: rgb(209, 209, 209);
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    padding-left: 0.71%;
    padding-right: 0.71%;

  }
    
  /*.bg {
    background-image: url(./logo/monkey.jpg);
    background-size: cover;background-repeat: no-repeat;
    background-attachment: fixed;
    width: 100%;
    background-color: white;
    height: fit-content;
    padding-bottom: 50px;
    
  
  }*/

  .pic {
    width: 220px;
    height: 330px;
    position: -webkit-sticky;
    position: sticky;
    bottom: 55px;
    left: 80%;
  }

  .bg2 {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    z-index: -1;
  }

 
  .headers{
    background-color: rgba(170, 169, 169, 0);
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 20px;
    padding-top: 20px;
    box-shadow: rgba(0, 0, 0, 0.13) 0px 2px 5px -1px, rgba(0, 0, 0, 0.089) 0px 1px 3px -1px;
    padding-left: 5%;
    padding-right: 5%;
    border-left-width: 0px;
    border-right-width: 0px;
    border-top-width: 0px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: none;
  }
  
  
  
  .title {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .navBar {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    cursor: pointer;
    display: none;
  } 
  
  .navBar div {
    margin-left: 10px;
    margin-right: 10px;
    font-weight: bold;
    color: black;
    padding-top: 15px;
    font-size: 15px;
  }
  
  .navBar div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-weight: bold;
    color: #ee314c;
    padding-top: 15px;
    font-size: 15px;
  }
  
  .rightNav {
    width: 60%;
    display: flex;
    justify-content: flex-end;
  }
  
  .sticky {
    position: fixed;
    top: 0;
    width: 100%;
  }
  
  .sticky + .content {
    padding-top: 60px;
  }
  
  .footerDetails a{
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  
  }
  
  .footerDetails a:hover{
    color: #ffffff;
    
    }
  
     /*................. STORY................... */
  
  .boxWrap2 {
    margin-top: 30px;
  }
  
  .storyHeader {
    font-family: "poppins";
    color: black;
    font-size: 50px;
    font-weight: bolder;
    margin-top: 10px;
  
  }
  
  .storyLeft {
    margin-top: 3%;
    margin-left: 3%;
  }
  
  .storyDetails {
    color: rgb(75, 75, 75);
    font-size: 15px;
    width: 50%;  
  }
  
    /*................. UTILITY................... */
  
  .utilityHeader {
    font-family: "poppins";
    color: black;
    font-size: 50px;
    font-weight: bolder;
  }  
  
  .utilityLeft {
    margin-top: 3%;
    margin-left: 3%;
  }
  
  .utilityDetails {
    color: rgb(0, 0, 0);
    font-size: 15px;
    width: 50%;
  }
  
  .box {
    background-color: rgb(105, 105, 105);
    border-radius: 5px;
    padding: 15px;
    color: white;
  }
  
  .box:hover {
    background-color: black;
    color: white;
  
  }
  
  /*.............. FAQ ..............*/
      
      
  summary {
    font-size: 17px;
    font-weight: 600;
    background-color: rgb(29, 29, 29);
    color: rgb(255, 255, 255);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    border-radius: 0.25rem;
    text-align: left;
    cursor: pointer;
    position: relative;
    
  
  }
  
  
  details > summary::after {
    position: absolute;
    content: "+";
    right: 20px;
  
  }
  details[open] > summary::after {
    position: absolute;
    content: "-";
    right: 20px;
  }
  details > summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary ~ * {
    animation: sweep .5s ease-in-out;
  }
  @keyframes sweep {
    0%    {opacity: 0; margin-top: -10px}
    100%  {opacity: 1; margin-top: 0px}
  }   
  
  .accordin{
  height: 600px;
  width: 40%;
  color: white;
  display: inline-block;
  flex-direction: column;
  margin-left: 3%;
  
  
  }
  
  .faq__content{
  
  color: rgba(0, 0, 0, 0.76);
  padding-bottom: 20px;
  text-align: left;
    
  }
  
  .faqTitle{
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
    font-size: 50px;
    font-weight: bolder;
    color: #000000;  
    font-family: "poppins";   
  
  }
  
  .faqbg{
    
  background-size: cover;
  width:100%;
  text-align:left;
  padding:2px;
  padding-top: 2%;
  padding-bottom: 20px;
  }
  
  /*.............. TEAM ..............*/
  
  .group {
  width: 40%;
  margin-left: 3%;
  }
  
  .teamD {
  display: flex;
  justify-content: left;
  flex-flow: column nowrap;
  }
  
  .teamTitle {
  font-family: "poppins";
  color: black;
  font-size: 50px;
  font-weight: bolder;
  margin-left: 3%;
  margin-bottom: 20px;
  }
  
  .guy1 {
    background-color: #ffffff67;
    padding: 1.5%;
    border-color: rgb(221, 221, 221);
    border-style: solid;
    border-width: 1.5px;
    transition: transform .3s;
  
  }
  
  .guy1:hover {
    background-color: #ee314c;
    -ms-transform: scale(0.8); /* IE 9 */
    -webkit-transform: scale(0.8); /* Safari 3-8 */
    transform: scale(1.1);
    border: none;
    color: #ffffff;
  
  } 
  
  .guy2 {
    background-color: #ffffff67;
    padding: 1.5%;
    border-color: rgb(221, 221, 221);
    border-style: solid;
    border-width: 1.5px;
    transition: transform .3s;
  
  }
  
  .guy2:hover {
    background-color: #ee314c;
    -ms-transform: scale(0.8); /* IE 9 */
    -webkit-transform: scale(0.8); /* Safari 3-8 */
    transform: scale(1.1);
    border: none;
    color: #ffffff;
  
  } 
  
  .guy3 {
    background-color: #ffffff67;
    padding: 1.5%;
    border-color: rgb(221, 221, 221);
    border-style: solid;
    border-width: 1.5px;  
    transition: transform .3s;
  
  }
  
  .guy3:hover {
    background-color: #ee314c;
    -ms-transform: scale(0.8); /* IE 9 */
    -webkit-transform: scale(0.8); /* Safari 3-8 */
    transform: scale(1.1);
    border: none;
    color: #ffffff;
  
  } 
  
  .guy4 {
    background-color: #ffffff67;
    padding: 1.5%;
    border-color: rgb(221, 221, 221);
    border-style: solid;
    border-width: 1.5px;
    transition: transform .3s;
  }
  
  .guy4:hover {
    background-color: #ee314c;
    -ms-transform: scale(0.8); /* IE 9 */
    -webkit-transform: scale(0.8); /* Safari 3-8 */
    transform: scale(1.1);
    border: none;
    color: #ffffff;
  
  } 
  
  .guy5 {
    background-color: #ffffff67;
    padding: 1.5%;
    border-color: rgb(221, 221, 221);
    border-style: solid;
    border-width: 1.5px;
    transition: transform .3s;
  }
  
  .guy5:hover {
    background-color: #ee314c;
    -ms-transform: scale(0.8); /* IE 9 */
    -webkit-transform: scale(0.8); /* Safari 3-8 */
    transform: scale(1.1);
    border: none;
    color: #ffffff;
  } 
  
  .man1 {
    background-image: url(./logo/1.png);
    width: 150px;
    height: 150px;
    background-size: cover;
  }
  
  .man2 {
    background-image: url(./logo/2.png);
    width: 150px;
    height: 150px;
    background-size: cover;
  }

  .man7 {
    background-image: url(./logo/newMem.jpg);
    width: 150px;
    height: 150px;
    background-size: cover;
  }
  
  .man3 {
    background-image: url(./logo/3.png);
    width: 150px;
    height: 150px;
    background-size: cover;
  }
  
  .man4 {
    background-image: url(./logo/5.png);
    width: 150px;
    height: 150px;
    background-size: cover;
  }
  
  .man5 {
    background-image: url(./logo/6.png);
    width: 150px;
    height: 150px;
    background-size: cover;
  }

  .man6 {
    background-image: url(./logo/26.png);
    width: 150px;
    height: 150px;
    background-size: cover;
  }
  
  .name1 {
    margin-top: 10%;
    text-align: left;
    font-size: larger;
    font-weight: bold;
  }
  
  .name2 {
    text-align: left;
    font-size: small;
  }
  
  /*.............. SOCIAL ICONS ..............*/
  
  .socialContainer {
    width: 25%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 10px;
  }
  
  /*.............. FOOTER ..............*/
  
  .footer {
    margin-top: 5%;
    margin-left: 3%;
  }
  
  .footerHeader {
    color: black;
    font-family: "Poppins";
    font-size: 50px;
    font-weight: bolder;
  }
  
  .footerP {
    color: rgb(75, 75, 75);
    font-size: 15px;
    width: 50%;
    margin-top: 20px;
  }
  
  .line {
    width: 50%;
    height: 1px;
    background-color: rgb(219, 219, 219);
    margin-top: 15px;
  }
  
  .footerBox1 {
    width: 300px;
    height: 100px;
    border-radius: 5px;
    margin-top: 25px;
    background-image: url(./assets/email_before.jpg);
    background-size: cover;
    border: none;
  }
  
  .footerBox1:hover { 
    background-image: url(./assets/email_after.jpg);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    color: rgb(226, 226, 226);
    cursor: pointer;
  
  }
  
  .footerBox2 {
    width: 300px;
    height: 100px;
    border-radius: 5px;
    margin-top: 25px;
    background-image: url(./assets/instagram_before.jpg);
    background-size: cover;
    border: none;
  }
  
  .footerBox2:hover { 
    background-image: url(./assets/instagram_after.jpg);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    color: rgb(226, 226, 226);
    cursor: pointer;
  
  }
  
  .footerBox3 {
    width: 300px;
    height: 100px;
    border-radius: 5px;
    margin-top: 25px;
    background-image: url(./assets/twitter_before.jpg);
    background-size: cover;
    border: none;
  }
  
  .footerBox3:hover { 
    background-image: url(./assets/twitter_after.jpg);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    color: rgb(226, 226, 226);
    cursor: pointer;
  
  }
  
  .footerBox4 {
    width: 300px;
    height: 100px;
    border-radius: 5px;
    margin-top: 25px;
    background-image: url(./assets/discord_before.jpg);
    background-size: cover;
    border: none;
  }
  
  .footerBox4:hover { 
    background-image: url(./assets/discord_after.jpg);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    color: rgb(226, 226, 226);
    cursor: pointer;
  
  }
  
  .iconsSection1 {
    display: flex;
    flex-flow: column nowrap;
  }
  
  .iconsSection2 {
    display: flex;
    flex-flow: column nowrap;
  }
  
  .footerDetails {
    color: black;
    font-family: "lucida sans unicode";
    font-size: 18px;
    font-weight: bolder;
    width: 300px;
    height: 100px;
    text-align: center;
    padding-left: 50px;
    padding-top: 30px;
  
  
  }
  
  .footerDetails2 {
    color: rgb(75, 75, 75);
    font-size: 12px;
  
  }
  
  .footerDetails:hover {
    color: rgb(226, 226, 226);
  }
  
  .footerDetails p{
  
   font-size: 9px ;
   color: rgb(161, 161, 161);
   margin-top: -3px;
  
  
  }
  
  .footerDetails2:hover {
    color: rgb(201, 201, 201);
  }
  
  }

@media only screen and (min-width: 768px) {
  .vidCont{
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    margin-top: 40px;
  }

  .videoBg {
    top: 0;
   left: 0px;
   width: 40%;
   box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;   margin-right: 20px;
   }

  .gameMonkeys{
    display: flex;
    flex-flow: column nowrap; 
  
  }
  .gMPic{
    width: 300px;
    height: 300px;
    border-radius: 8px;
    margin-top: 8px;
  }
  .mintedAmount{
    margin-left: 3%;
    font-size: 25px;
    font-weight: bold;
    color: white;
    background-color: #3f3f3f;
    border-radius: 5px;
    width: max-content;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;}
  .carouselIMG{
    height:150px;
    width:150px;
  }
  .headerImg{

    width: 100%;
    
      }

  .successfully{
    margin-top: 1%;
    font-size: 20px;
    color: #53c245;
    margin-left: 3%;

  }
  
  .loadingContainer{
    margin-left: 3%;
    margin-top: 1%;
  }
  
  .errorMessage{
  margin-left: 3%;
  margin-top: 1%;
  font-size: 20px;
  color: #ec556c;
  
  } 
  .loadingText {
    font-size: 20px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;}
  
  @keyframes dots {
    0%, 20% {
      color: rgba(0,0,0,0);
      text-shadow:
        .25em 0 0 rgba(0,0,0,0),
        .5em 0 0 rgba(0,0,0,0);}
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0,0,0,0),
        .5em 0 0 rgba(0,0,0,0);}
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0,0,0,0);}
    80%, 100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);}}
  
  .btnfos-0-3{
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 30px;
    font-family: "Poppins";
    border-radius: 5px;
    border-style: outset;
    border-color: white;
    border-width: 2px;
    font-family: "Poppins";
    cursor: pointer;
  
  }
  
  .btnfos-0-3:active {
    background-color: #777777;
    transform: translateY(4px);
  }
  
  .btnfos-0-3:hover{
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
  }
    
  .mintbuttondiv{
    margin-top: 2%;
    margin-left: 3%;
  
  }
  .minting_count_button{
    display: flex;
    flex-flow: row nowrap;
    justify-content: left;
    margin-left: 6.5%;
    margin-top: 3%;
  }  
  
  .btnfos-0-2 {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border:none;
    border-radius: 5px;
    border-style: outset;
    cursor: pointer;
  
    
  }
  
  .nftamount{
    padding-left: 10px;
    padding-right: 10px;
    font-size: 30px;
    font-family: "Poppins";
  
  }
    
  .walletConnect{
    margin-left: 3%;
    margin-top: 3%;
  }  
  
  .wallet3{
    padding-top: 25px;
    padding-bottom: 25px;
    width: 300px;
    letter-spacing: 5px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
    font-size: 25px;
    font-weight: bolder;
    border-radius: 5px;
    cursor: pointer;
    border-style: none;
    font-family: "Poppins";
    background-color: black;
    color: white;
    box-shadow: 0 5px 15px black;
  } 
  
  .wallet3:active {
    background-color: #bbbbbb;
    transform: translateY(4px);
  }
  
  .wallet3:hover{
    color: rgb(0, 0, 0);
    background-color: #ececec;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  }
  
    .storyB {
        background-color: black;
        width: 15%;
        text-align: left;
        padding-top: 30px;
        padding-bottom: 5px;
        border: none;
        color: white;
        border-radius: 5px;
        margin-right: 0.71%;
        margin-left: 0.71%;
        font-weight: bold;
        font-size: smaller;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    
      }

      .storyB:hover{
        color: white;
      }
    
      .buttonsDown {
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
      }  
    
      .btnSet {
        background-color: rgb(209, 209, 209);
        padding-top: 10px;
        padding-bottom: 10px;
        display: flex;
        justify-content: center;
        padding-left: 0.71%;
        padding-right: 0.71%;
      }
        
      /*.bg {
        background-image: url(./logo/monkey.jpg);
        background-size: cover;background-repeat: no-repeat;
        background-attachment: fixed;
        width: 100%;
        background-color: white;
        height: fit-content;
        padding-bottom: 50px;
        
      
      }*/

      .pic {
        width: 330px;
        height: 495px;
        position: -webkit-sticky;
        position: sticky;
        bottom: 55px;
        left: 80%;
      }
    
      .bg2 {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        z-index: -1;
  
      }     
     
      .headers{
        background-color: rgba(170, 169, 169, 0);
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        padding-bottom: 20px;
        padding-top: 20px;
        box-shadow: rgba(0, 0, 0, 0.13) 0px 2px 5px -1px, rgba(0, 0, 0, 0.089) 0px 1px 3px -1px;
        padding-left: 5%;
        padding-right: 5%;
        border-left-width: 0px;
        border-right-width: 0px;
        border-top-width: 0px;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        display: none;
      }
      
      
      
      .title {
        display: flex;
        flex-flow: row nowrap;
      }
      
      .navBar {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        cursor: pointer;
        display: none;
      } 
      
      .navBar div {
        margin-left: 10px;
        margin-right: 10px;
        font-weight: bold;
        color: black;
        padding-top: 15px;
        font-size: 15px;
      }
      
      .navBar div:hover {
        margin-left: 10px;
        margin-right: 10px;
        font-weight: bold;
        color: #ee314c;
        padding-top: 15px;
        font-size: 15px;
      }
      
      .rightNav {
        width: 60%;
        display: flex;
        justify-content: flex-end;
      }
      
      .sticky {
        position: fixed;
        top: 0;
        width: 100%;
      }
      
      .sticky + .content {
        padding-top: 60px;
      }
      
      .footerDetails a{
      color: #000000;
      text-decoration: none;
      cursor: pointer;
      
      }
      
      .footerDetails a:hover{
        color: #ffffff;
        
        }
      
         /*................. STORY................... */
      
      .boxWrap2 {
        margin-top: 30px;
      }
      
      .storyHeader {
        font-family: "poppins";
        color: black;
        font-size: 50px;
        font-weight: bolder;
        margin-top: 10px;
      
      }
      
      .storyLeft {
        margin-top: 3%;
        margin-left: 3%;
      }
      
      .storyDetails {
        color: rgb(75, 75, 75);
        font-size: 15px;
        width: 50%;  
      }
      
        /*................. UTILITY................... */
      
      .utilityHeader {
        font-family: "poppins";
        color: black;
        font-size: 50px;
        font-weight: bolder;
      }  
      
      .utilityLeft {
        margin-top: 3%;
        margin-left: 3%;
      }
      
      .utilityDetails {
        color: rgb(0, 0, 0);
        font-size: 15px;
        width: 50%;
      }
      
      .box {
        background-color: rgb(105, 105, 105);
        border-radius: 5px;
        padding: 15px;
        color: white;
      }
      
      .box:hover {
        background-color: black;
        color: white;
      
      }
      
      /*.............. FAQ ..............*/
          
          
      summary {
        font-size: 17px;
        font-weight: 600;
        background-color: rgb(29, 29, 29);
        color: rgb(255, 255, 255);
        padding: 1rem;
        margin-bottom: 1rem;
        outline: none;
        border-radius: 0.25rem;
        text-align: left;
        cursor: pointer;
        position: relative;
        
      
      }
      
      
      details > summary::after {
        position: absolute;
        content: "+";
        right: 20px;
      
      }
      details[open] > summary::after {
        position: absolute;
        content: "-";
        right: 20px;
      }
      details > summary::-webkit-details-marker {
        display: none;
      }
      
      details[open] summary ~ * {
        animation: sweep .5s ease-in-out;
      }
      @keyframes sweep {
        0%    {opacity: 0; margin-top: -10px}
        100%  {opacity: 1; margin-top: 0px}
      }   
      
      .accordin{
      height: 600px;
      width: 40%;
      color: white;
      display: inline-block;
      flex-direction: column;
      margin-left: 3%;
      
      
      }
      
      .faq__content{
      
      color: rgba(0, 0, 0, 0.76);
      padding-bottom: 20px;
      text-align: left;
        
      }
      
      .faqTitle{
        margin-top: 20px;
        margin-bottom: 20px;
        text-align: left;
        font-size: 50px;
        font-weight: bolder;
        color: #000000;  
        font-family: "poppins";   
      
      }
      
      .faqbg{
        
      background-size: cover;
      width:100%;
      text-align:left;
      padding:2px;
      padding-top: 2%;
      padding-bottom: 20px;
      }
      
      /*.............. TEAM ..............*/
      
      .group {
      width: 40%;
      margin-left: 3%;
      }
      
      .teamD {
      display: flex;
      justify-content: space-between;
      flex-flow: row nowrap;

      }
      
      .teamTitle {
      font-family: "poppins";
      color: black;
      font-size: 50px;
      font-weight: bolder;
      margin-left: 3%;
      margin-bottom: 20px;
      }
      
      .guy1 {
        background-color: #ffffff67;
        padding: 1%;
        border-color: rgb(221, 221, 221);
        border-style: solid;
        border-width: 1.5px;
        transition: transform .3s;
      
      }
      
      .guy1:hover {
        background-color: #ee314c;
        -ms-transform: scale(0.8); /* IE 9 */
        -webkit-transform: scale(0.8); /* Safari 3-8 */
        transform: scale(1.1);
        border: none;
        color: #ffffff;
      
      } 
      
      .guy2 {
        background-color: #ffffff67;
        padding: 1%;
        border-color: rgb(221, 221, 221);
        border-style: solid;
        border-width: 1.5px;
        transition: transform .3s;
      
      }
      
      .guy2:hover {
        background-color: #ee314c;
        -ms-transform: scale(0.8); /* IE 9 */
        -webkit-transform: scale(0.8); /* Safari 3-8 */
        transform: scale(1.1);
        border: none;
        color: #ffffff;
      
      } 
      
      .guy3 {
        background-color: #ffffff67;
        padding: 1%;
        border-color: rgb(221, 221, 221);
        border-style: solid;
        border-width: 1.5px;  
        transition: transform .3s;
      
      }
      
      .guy3:hover {
        background-color: #ee314c;
        -ms-transform: scale(0.8); /* IE 9 */
        -webkit-transform: scale(0.8); /* Safari 3-8 */
        transform: scale(1.1);
        border: none;
        color: #ffffff;
      
      } 
      
      .guy4 {
        background-color: #ffffff67;
        padding: 1%;
        border-color: rgb(221, 221, 221);
        border-style: solid;
        border-width: 1.5px;
        transition: transform .3s;
      }
      
      .guy4:hover {
        background-color: #ee314c;
        -ms-transform: scale(0.8); /* IE 9 */
        -webkit-transform: scale(0.8); /* Safari 3-8 */
        transform: scale(1.1);
        border: none;
        color: #ffffff;
      
      } 
      
      .guy5 {
        background-color: #ffffff67;
        padding: 1%;
        border-color: rgb(221, 221, 221);
        border-style: solid;
        border-width: 1.5px;
        transition: transform .3s;
      }
      
      .guy5:hover {
        background-color: #ee314c;
        -ms-transform: scale(0.8); /* IE 9 */
        -webkit-transform: scale(0.8); /* Safari 3-8 */
        transform: scale(1.1);
        border: none;
        color: #ffffff;
      } 
      
      .man1 {
        background-image: url(./logo/1.png);
        width: 90px;
        height: 90px;
        background-size: cover;
      }
      
      .man2 {
        background-image: url(./logo/2.png);
        width: 90px;
        height: 90px;
        background-size: cover;
      }

      .man7 {
        background-image: url(./logo/newMem.jpg);
        width: 90px;
        height: 90px;
        background-size: cover;
      }
      
      .man3 {
        background-image: url(./logo/3.png);
        width: 90px;
        height: 90px;
        background-size: cover;
      }
      
      .man4 {
        background-image: url(./logo/5.png);
        width: 90px;
        height: 90px;
        background-size: cover;
      }
      
      .man5 {
        background-image: url(./logo/6.png);
        width: 90px;
        height: 90px;
        background-size: cover;
      }

      .man6 {
        background-image: url(./logo/26.png);
        width: 90px;
        height: 90px;
        background-size: cover;
      }
      
      .name1 {
        margin-top: 10%;
        text-align: left;
        font-size: 13px;
        font-weight: bold;
      }
      
      .name2 {
        text-align: left;
        font-size: 10px;
      }
      
      /*.............. SOCIAL ICONS ..............*/
      
      .socialContainer {
        width: 25%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-evenly;
        margin-top: 10px;
      }
      
      /*.............. FOOTER ..............*/
      
      .footer {
        margin-top: 5%;
        margin-left: 3%;
      }
      
      .footerHeader {
        color: black;
        font-family: "Poppins";
        font-size: 50px;
        font-weight: bolder;
      }
      
      .footerP {
        color: rgb(75, 75, 75);
        font-size: 15px;
        width: 50%;
        margin-top: 20px;
      }
      
      .line {
        width: 50%;
        height: 1px;
        background-color: rgb(219, 219, 219);
        margin-top: 15px;
      }
      
      .footerBox1 {
        width: 300px;
        height: 100px;
        border-radius: 5px;
        margin-top: 25px;
        background-image: url(./assets/email_before.jpg);
        background-size: cover;
        border: none;
      }
      
      .footerBox1:hover { 
        background-image: url(./assets/email_after.jpg);
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        color: rgb(226, 226, 226);
        cursor: pointer;
      
      }
      
      .footerBox2 {
        width: 300px;
        height: 100px;
        border-radius: 5px;
        margin-top: 25px;
        background-image: url(./assets/instagram_before.jpg);
        background-size: cover;
        margin-left: 15px;
        border: none;
      }
      
      .footerBox2:hover { 
        background-image: url(./assets/instagram_after.jpg);
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        color: rgb(226, 226, 226);
        cursor: pointer;
      
      }
      
      .footerBox3 {
        width: 300px;
        height: 100px;
        border-radius: 5px;
        margin-top: 25px;
        background-image: url(./assets/twitter_before.jpg);
        background-size: cover;
        border: none;
      }
      
      .footerBox3:hover { 
        background-image: url(./assets/twitter_after.jpg);
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        color: rgb(226, 226, 226);
        cursor: pointer;
      
      }
      
      .footerBox4 {
        width: 300px;
        height: 100px;
        border-radius: 5px;
        margin-top: 25px;
        background-image: url(./assets/discord_before.jpg);
        background-size: cover;
        margin-left: 15px;
        border: none;
      }
      
      .footerBox4:hover { 
        background-image: url(./assets/discord_after.jpg);
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        color: rgb(226, 226, 226);
        cursor: pointer;
      
      }
      
      .iconsSection1 {
        display: flex;
        flex-flow: row nowrap;
      }
      
      .iconsSection2 {
        display: flex;
        flex-flow: row nowrap;
      }
      
      .footerDetails {
        color: black;
        font-family: "lucida sans unicode";
        font-size: 18px;
        font-weight: bolder;
        width: 300px;
        height: 100px;
        text-align: center;
        padding-left: 50px;
        padding-top: 30px;
      
      
      }
      
      .footerDetails2 {
        color: rgb(75, 75, 75);
        font-size: 12px;
      
      }
      
      .footerDetails:hover {
        color: rgb(226, 226, 226);
      }
      
      .footerDetails p{
      
       font-size: 9px ;
       color: rgb(161, 161, 161);
       margin-top: -3px;
      
      
      }
      
      .footerDetails2:hover {
        color: rgb(201, 201, 201);
      }
      
  } 

@media only screen and (min-width: 992px) {
  
  .vidCont{
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    margin-top: 40px;
  }

  .videoBg {
    top: 0;
   left: 0px;
   width: 40%;
   box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;   margin-right: 20px;
   }
  
  .gameMonkeys{
    display: flex;
    flex-flow: column nowrap; 
  
  }
  .gMPic{
    width: 300px;
    height: 300px;
    border-radius: 8px;
    margin-top: 8px;
  }

  .mintedAmount{
    margin-left: 3%;
    font-size: 25px;
    font-weight: bold;
    color: white;
    background-color: #3f3f3f;
    border-radius: 5px;
    width: max-content;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;}

  .carouselIMG{
    height:200px;
    width:200px;
  }
  
  .headerImg{

    width: 100%;
    
      }
  
  .successfully{
    margin-top: 1%;
    font-size: 20px;
    color: #53c245;
    margin-left: 3%;

  }
  
  .loadingContainer{
    margin-left: 3%;
    margin-top: 1%;
  }
  
  .errorMessage{
  margin-left: 3%;
  margin-top: 1%;
  font-size: 20px;
  color: #ec556c;
  
  } 
  .loadingText {
    font-size: 20px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;}
  
  @keyframes dots {
    0%, 20% {
      color: rgba(0,0,0,0);
      text-shadow:
        .25em 0 0 rgba(0,0,0,0),
        .5em 0 0 rgba(0,0,0,0);}
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0,0,0,0),
        .5em 0 0 rgba(0,0,0,0);}
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0,0,0,0);}
    80%, 100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);}}
  
  .btnfos-0-3{
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 30px;
    font-family: "Poppins";
    border-radius: 5px;
    border-style: outset;
    border-color: white;
    border-width: 2px;
    font-family: "Poppins";
    cursor: pointer;
  
  }
  
  .btnfos-0-3:active {
    background-color: #777777;
    transform: translateY(4px);
  }
  
  .btnfos-0-3:hover{
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
  }
    
  .mintbuttondiv{
    margin-top: 2%;
    margin-left: 3%;
  
  }
  .minting_count_button{
    display: flex;
    flex-flow: row nowrap;
    justify-content: left;
    margin-left: 6.5%;
    margin-top: 3%;
  }  
  
  .btnfos-0-2 {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border:none;
    border-radius: 5px;
    border-style: outset;
    cursor: pointer;
  
    
  }
  
  .nftamount{
    padding-left: 10px;
    padding-right: 10px;
    font-size: 30px;
    font-family: "Poppins";
  
  }
    
  .walletConnect{
    margin-left: 3%;
    margin-top: 3%;
  }  
  
  .wallet3{
    padding-top: 25px;
    padding-bottom: 25px;
    width: 300px;
  letter-spacing: 5px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
    font-size: 25px;
    font-weight: bolder;
    border-radius: 5px;
    cursor: pointer;
    border-style: none;
    font-family: "Poppins";
    background-color: black;
    color: white;
    box-shadow: 0 5px 15px black;
  } 
  
  .wallet3:active {
    background-color: #bbbbbb;
    transform: translateY(4px);
  }
  
  .wallet3:hover{
    color: rgb(0, 0, 0);
    background-color: #ececec;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  }
  
  .storyB {
      background-color: black;
      width: 15%;
      text-align: left;
      padding-top: 30px;
      padding-bottom: 5px;
      border: none;
      color: white;
      border-radius: 5px;
      margin-right: 0.71%;
      margin-left: 0.71%;
      font-weight: bold;
      font-size: smaller;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  
    }

    .storyB:hover{
      color: white;
    }
    
  
    .btnSet {
      background-color: rgb(209, 209, 209);
      padding-top: 10px;
      padding-bottom: 10px;
      display: flex;
      justify-content: center;
      padding-left: 0.71%;
      padding-right: 0.71%;
      display: none;
      margin-bottom: 0;    }
      
    .bg {
      /*background-image: url(./logo/monkey.jpg);
      background-size: cover;background-repeat: no-repeat;
      background-attachment: fixed;
      width: 100%;
      background-color: white;*/
      height: fit-content;
    
    
    }
  
    .pic {
      width: 360px;
      height: 540px;
      position: sticky;
      position: -webkit-sticky;
      left: 70%;
      margin-top: -30%;
      bottom: 0;


    }
  
    .bg2 {
      width: 100%;
      display: flex;
      z-index: -1;


    }
  
   
    .headers{
      background-color: rgba(170, 169, 169, 0);
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      padding-bottom: 20px;
      padding-top: 20px;
      box-shadow: rgba(0, 0, 0, 0.13) 0px 2px 5px -1px, rgba(0, 0, 0, 0.089) 0px 1px 3px -1px;
      padding-left: 5%;
      padding-right: 5%;
      border-left-width: 0px;
      border-right-width: 0px;
      border-top-width: 0px;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
    }
    
    
    
    .title {
      display: flex;
      flex-flow: row nowrap;
    }
    
    .navBar {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      cursor: pointer;
    } 
    
    .navBar div {
      margin-left: 10px;
      margin-right: 10px;
      font-weight: bold;
      color: black;
      padding-top: 15px;
      font-size: 11px;
    }
    
    .navBar div:hover {
      margin-left: 10px;
      margin-right: 10px;
      font-weight: bold;
      color: #ee314c;
      padding-top: 15px;
      font-size: 11px;
    }
    
    .rightNav {
      width: 60%;
      display: flex;
      justify-content: flex-end;
    }
    
    .sticky {
      position: fixed;
      top: 0;
      width: 100%;
    }
    
    .sticky + .content {
      padding-top: 60px;
    }
    
    .footerDetails a{
    color: #000000;
    text-decoration: none;
    cursor: pointer;
    
    }
    
    .footerDetails a:hover{
      color: #ffffff;
      
      }
    
       /*................. STORY................... */
    
    .boxWrap2 {
      margin-top: 30px;
    }
    
    .storyHeader {
      font-family: "poppins";
      color: black;
      font-size: 50px;
      font-weight: bolder;
      margin-top: 10px;
    
    }
    
    .storyLeft {
      margin-top: 3%;
      margin-left: 3%;
    }
    
    .storyDetails {
      color: rgb(75, 75, 75);
      font-size: 15px;
      width: 50%;  
    }
    
      /*................. UTILITY................... */
    
    .utilityHeader {
      font-family: "poppins";
      color: black;
      font-size: 50px;
      font-weight: bolder;
    }  
    
    .utilityLeft {
      margin-top: 3%;
      margin-left: 3%;
    }
    
    .utilityDetails {
      color: rgb(0, 0, 0);
      font-size: 15px;
      width: 50%;
    }
    
    .box {
      background-color: rgb(105, 105, 105);
      border-radius: 5px;
      padding: 15px;
      color: white;
    }
    
    .box:hover {
      background-color: black;
      color: white;
    
    }
    
    /*.............. FAQ ..............*/
        
        
    summary {
      font-size: 17px;
      font-weight: 600;
      background-color: rgb(29, 29, 29);
      color: rgb(255, 255, 255);
      padding: 1rem;
      margin-bottom: 1rem;
      outline: none;
      border-radius: 0.25rem;
      text-align: left;
      cursor: pointer;
      position: relative;
      
    
    }
    
    
    details > summary::after {
      position: absolute;
      content: "+";
      right: 20px;
    
    }
    details[open] > summary::after {
      position: absolute;
      content: "-";
      right: 20px;
    }
    details > summary::-webkit-details-marker {
      display: none;
    }
    
    details[open] summary ~ * {
      animation: sweep .5s ease-in-out;
    }
    @keyframes sweep {
      0%    {opacity: 0; margin-top: -10px}
      100%  {opacity: 1; margin-top: 0px}
    }   
    
    .accordin{
    height: 600px;
    width: 40%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: 3%;
    
    
    }
    
    .faq__content{
    
    color: rgba(0, 0, 0, 0.76);
    padding-bottom: 20px;
    text-align: left;
      
    }
    
    .faqTitle{
      margin-top: 20px;
      margin-bottom: 20px;
      text-align: left;
      font-size: 50px;
      font-weight: bolder;
      color: #000000;  
      font-family: "poppins";   
    
    }
    
    .faqbg{
      
    background-size: cover;
    width:100%;
    text-align:left;
    padding:2px;
    padding-top: 2%;
    padding-bottom: 20px;
    }
    
    /*.............. TEAM ..............*/
    
    .group {
    width: 40%;
    margin-left: 3%;
    }
    
    .teamD {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    }
    
    .teamTitle {
    font-family: "poppins";
    color: black;
    font-size: 50px;
    font-weight: bolder;
    margin-left: 3%;
    margin-bottom: 20px;
    }
    
    .guy1 {
      background-color: #ffffff67;
      padding: 1%;
      border-color: rgb(221, 221, 221);
      border-style: solid;
      border-width: 1.5px;
      transition: transform .3s;
    
    }
    
    .guy1:hover {
      background-color: #ee314c;
      -ms-transform: scale(0.8); /* IE 9 */
      -webkit-transform: scale(0.8); /* Safari 3-8 */
      transform: scale(1.1);
      border: none;
      color: #ffffff;
    
    } 
    
    .guy2 {
      background-color: #ffffff67;
      padding: 1%;
      border-color: rgb(221, 221, 221);
      border-style: solid;
      border-width: 1.5px;
      transition: transform .3s;
    
    }
    
    .guy2:hover {
      background-color: #ee314c;
      -ms-transform: scale(0.8); /* IE 9 */
      -webkit-transform: scale(0.8); /* Safari 3-8 */
      transform: scale(1.1);
      border: none;
      color: #ffffff;
    
    } 
    
    .guy3 {
      background-color: #ffffff67;
      padding: 1%;
      border-color: rgb(221, 221, 221);
      border-style: solid;
      border-width: 1.5px;  
      transition: transform .3s;
    
    }
    
    .guy3:hover {
      background-color: #ee314c;
      -ms-transform: scale(0.8); /* IE 9 */
      -webkit-transform: scale(0.8); /* Safari 3-8 */
      transform: scale(1.1);
      border: none;
      color: #ffffff;
    
    } 
    
    .guy4 {
      background-color: #ffffff67;
      padding: 1%;
      border-color: rgb(221, 221, 221);
      border-style: solid;
      border-width: 1.5px;
      transition: transform .3s;
    }
    
    .guy4:hover {
      background-color: #ee314c;
      -ms-transform: scale(0.8); /* IE 9 */
      -webkit-transform: scale(0.8); /* Safari 3-8 */
      transform: scale(1.1);
      border: none;
      color: #ffffff;
    
    } 
    
    .guy5 {
      background-color: #ffffff67;
      padding: 1%;
      border-color: rgb(221, 221, 221);
      border-style: solid;
      border-width: 1.5px;
      transition: transform .3s;
    }
    
    .guy5:hover {
      background-color: #ee314c;
      -ms-transform: scale(0.8); /* IE 9 */
      -webkit-transform: scale(0.8); /* Safari 3-8 */
      transform: scale(1.1);
      border: none;
      color: #ffffff;
    } 
    
    .man1 {
      background-image: url(./logo/1.png);
      width: 105px;
      height: 105px;
      background-size: cover;
    }
    
    .man2 {
      background-image: url(./logo/2.png);
      width: 105px;
      height: 105px;
      background-size: cover;
    }

    .man7 {
      background-image: url(./logo/newMem.jpg);
      width: 105px;
      height: 105px;
      background-size: cover;
    }
 
    
    .man3 {
      background-image: url(./logo/3.png);
      width: 105px;
      height: 105px;
      background-size: cover;
    }
    
    .man4 {
      background-image: url(./logo/5.png);
      width: 105px;
      height: 105px;
      background-size: cover;
    }
    
    .man5 {
      background-image: url(./logo/6.png);
      width: 105px;
      height: 105px;
      background-size: cover;
    }

    .man6 {
      background-image: url(./logo/26.png);
      width: 105px;
      height: 105px;
      background-size: cover;
    }
    
    .name1 {
      margin-top: 10%;
      text-align: left;
      font-size: 14px;
      font-weight: bold;
    }
    
    .name2 {
      text-align: left;
      font-size: smaller;
    }
    
    /*.............. SOCIAL ICONS ..............*/
    
    .socialContainer {
      width: 25%;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-evenly;
      margin-top: 10px;
    }
    
    /*.............. FOOTER ..............*/
    
    .footer {
      margin-top: 5%;
      margin-left: 3%;
    }
    
    .footerHeader {
      color: black;
      font-family: "Poppins";
      font-size: 50px;
      font-weight: bolder;
    }
    
    .footerP {
      color: rgb(75, 75, 75);
      font-size: 15px;
      width: 50%;
      margin-top: 20px;
    }
    
    .line {
      width: 50%;
      height: 1px;
      background-color: rgb(219, 219, 219);
      margin-top: 15px;
    }
    
    .footerBox1 {
      width: 300px;
      height: 100px;
      border-radius: 5px;
      margin-top: 25px;
      background-image: url(./assets/email_before.jpg);
      background-size: cover;
      border: none;
    }
    
    .footerBox1:hover { 
      background-image: url(./assets/email_after.jpg);
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      color: rgb(226, 226, 226);
      cursor: pointer;
    
    }
    
    .footerBox2 {
      width: 300px;
      height: 100px;
      border-radius: 5px;
      margin-top: 25px;
      background-image: url(./assets/instagram_before.jpg);
      background-size: cover;
      margin-left: 15px;
      border: none;
    }
    
    .footerBox2:hover { 
      background-image: url(./assets/instagram_after.jpg);
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      color: rgb(226, 226, 226);
      cursor: pointer;
    
    }
    
    .footerBox3 {
      width: 300px;
      height: 100px;
      border-radius: 5px;
      margin-top: 25px;
      background-image: url(./assets/twitter_before.jpg);
      background-size: cover;
      border: none;
    }
    
    .footerBox3:hover { 
      background-image: url(./assets/twitter_after.jpg);
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      color: rgb(226, 226, 226);
      cursor: pointer;
    
    }
    
    .footerBox4 {
      width: 300px;
      height: 100px;
      border-radius: 5px;
      margin-top: 25px;
      background-image: url(./assets/discord_before.jpg);
      background-size: cover;
      margin-left: 15px;
      border: none;
    }
    
    .footerBox4:hover { 
      background-image: url(./assets/discord_after.jpg);
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      color: rgb(226, 226, 226);
      cursor: pointer;
    
    }
    
    .iconsSection1 {
      display: flex;
      flex-flow: row nowrap;
    }
    
    .iconsSection2 {
      display: flex;
      flex-flow: row nowrap;
    }
    
    .footerDetails {
      color: black;
      font-family: "lucida sans unicode";
      font-size: 18px;
      font-weight: bolder;
      width: 300px;
      height: 100px;
      text-align: center;
      padding-left: 50px;
      padding-top: 30px;
    
    
    }
    
    .footerDetails2 {
      color: rgb(75, 75, 75);
      font-size: 12px;
    
    }
    
    .footerDetails:hover {
      color: rgb(226, 226, 226);
    }
    
    .footerDetails p{
    
     font-size: 9px ;
     color: rgb(161, 161, 161);
     margin-top: -3px;
    
    
    }
    
    .footerDetails2:hover {
      color: rgb(201, 201, 201);
    }
    
  }
   
@media only screen and (min-width: 1200px) {
  
  .vidCont{
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    margin-top: 40px;
  }

  .videoBg {
    top: 0;
   left: 0px;
   width: 40%;
   box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;   margin-right: 20px;
   }
 

.gameMonkeys{
  display: flex;
  flex-flow: row nowrap; 

}

.gMPic{
  width: 150px;
  height: 150px;
  border-radius: 8px;
  margin-right: 8px;
}

.carouselIMG{
  height:200px;
  width:200px;
}


  .headerImg{

width: 100%;

  }

.successfully{
  margin-top: 1%;
  font-size: 20px;
  color: #53c245;
  margin-left: 3%;

}

.loadingContainer{
  margin-left: 3%;
  margin-top: 1%;
}

.errorMessage{
margin-left: 3%;
margin-top: 1%;
font-size: 20px;
color: #ec556c;

} 
.loadingText {
  font-size: 20px;
}

/* loading dots */


.loadingText:after {
  content: '.';
  animation: dots 1s steps(5, end) infinite;}

@keyframes dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  40% {
    color: rgb(151, 151, 151);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  60% {
    text-shadow:
      .25em 0 0 rgb(124, 124, 124),
      .5em 0 0 rgba(0,0,0,0);}
  80%, 100% {
    text-shadow:
      .25em 0 0 rgb(121, 121, 121),
      .5em 0 0 rgb(121, 121, 121);}}

.btnfos-0-3{
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 30px;
  font-family: "Poppins";
  border-radius: 5px;
  border-style: outset;
  border-color: white;
  border-width: 2px;
  font-family: "Poppins";
  cursor: pointer;

}

.btnfos-0-3:active {
  background-color: #777777;
  transform: translateY(4px);
}

.btnfos-0-3:hover{
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
}
  
.mintbuttondiv{
  margin-top: 2%;
  margin-left: 3%;

}
.minting_count_button{
  display: flex;
  flex-flow: row nowrap;
  justify-content: left;
  margin-top: 3%;
}  

.btnfos-0-2 {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border:none;
  border-radius: 5px;
  border-style: outset;
  cursor: pointer;

  
}

.nftamount{
  padding-left: 10px;
  padding-right: 10px;
  font-size: 30px;
  font-family: "Poppins";

}
  
.walletConnect{
  margin-left: 3%;
  margin-top: 3%;
}  

.wallet3{
  padding-top: 25px;
  padding-bottom: 25px;
  width: 300px;
  letter-spacing: 5px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  font-size: 25px;
  font-weight: bolder;
  border-radius: 5px;
  cursor: pointer;
  border-style: none;
  font-family: "Poppins";
  background-color: black;
  color: white;
  box-shadow: 0 5px 15px black;
} 

.wallet3:active {
  background-color: #bbbbbb;
  transform: translateY(4px);
}

.wallet3:hover{
  color: rgb(0, 0, 0);
  background-color: #ececec;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.buttonsDown {
  display: none;
}

.bg2 {
  display: none;
}

.mintedAmount{
  margin-left: 3%;
  font-size: 25px;
  font-weight: bold;
  color: white;
  background-color: #3f3f3f;
  border-radius: 5px;
  width: max-content;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;}

.bg {
 /* background-image: url(./logo/monkey.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100%;
  background-color: white;*/
  height: fit-content;
  /*padding-bottom: 50px;*/
}

.pic {
 /* display: none;*/
  width: 360px;
  height: 540px;
  position: sticky;
  position: -webkit-sticky;
  left: 70%;
  margin-top: -30%;
  bottom: 0;
}

.headers{
  background-color: rgba(170, 169, 169, 0);
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding-bottom: 20px;
  padding-top: 20px;
  box-shadow: rgba(0, 0, 0, 0.13) 0px 2px 5px -1px, rgba(0, 0, 0, 0.089) 0px 1px 3px -1px;
  padding-left: 5%;
  padding-right: 5%;
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}



.title {
  display: flex;
  flex-flow: row nowrap;
}

.navBar {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  cursor: pointer;
} 

.navBar div {
  margin-left: 10px;
  margin-right: 10px;
  font-weight: bold;
  color: black;
  padding-top: 15px;
  font-size: 15px;
}

.navBar div:hover {
  margin-left: 10px;
  margin-right: 10px;
  font-weight: bold;
  color: #ee314c;
  padding-top: 15px;
  font-size: 15px;
}

.rightNav {
  width: 60%;
  display: flex;
  justify-content: flex-end;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.sticky + .content {
  padding-top: 60px;
}

.footerDetails a{
color: #000000;
text-decoration: none;
cursor: pointer;

}

.footerDetails a:hover{
  color: #ffffff;
  
  }

   /*................. STORY................... */

.boxWrap2 {
  margin-top: 30px;
}

.storyHeader {
  font-family: "poppins";
  color: black;
  font-size: 50px;
  font-weight: bolder;
  margin-top: 10px;

}

.storyLeft {
  margin-top: 3%;
  margin-left: 3%;
}

.storyDetails {
  color: rgb(75, 75, 75);
  font-size: 15px;
  width: 50%;  
}

  /*................. UTILITY................... */

.utilityHeader {
  font-family: "poppins";
  color: black;
  font-size: 50px;
  font-weight: bolder;
}  

.utilityLeft {
  margin-top: 3%;
  margin-left: 3%;
}

.utilityDetails {
  color: rgb(0, 0, 0);
  font-size: 15px;
  width: 50%;
}

.box {
  background-color: rgb(105, 105, 105);
  border-radius: 5px;
  padding: 15px;
  color: white;
}

.box:hover {
  background-color: black;
  color: white;

}

/*.............. FAQ ..............*/
    
    
summary {
  font-size: 17px;
  font-weight: 600;
  background-color: rgb(29, 29, 29);
  color: rgb(255, 255, 255);
  padding: 1rem;
  margin-bottom: 1rem;
  outline: none;
  border-radius: 0.25rem;
  text-align: left;
  cursor: pointer;
  position: relative;
  

}


details > summary::after {
  position: absolute;
  content: "+";
  right: 20px;

}
details[open] > summary::after {
  position: absolute;
  content: "-";
  right: 20px;
}
details > summary::-webkit-details-marker {
  display: none;
}

details[open] summary ~ * {
  animation: sweep .5s ease-in-out;
}
@keyframes sweep {
  0%    {opacity: 0; margin-top: -10px}
  100%  {opacity: 1; margin-top: 0px}
}   

.accordin{
height: 600px;
width: 40%;
color: white;
display: inline-block;
flex-direction: column;
margin-left: 3%;


}

.faq__content{

color: rgba(0, 0, 0, 0.76);
padding-bottom: 20px;
text-align: left;
  
}

.faqTitle{
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: left;
  font-size: 50px;
  font-weight: bolder;
  color: #000000;  
  font-family: "poppins";   

}

.faqbg{
  
background-size: cover;
width:100%;
text-align:left;
padding:2px;
padding-top: 2%;
padding-bottom: 20px;
}

/*.............. TEAM ..............*/

.group {
width: 30%;
margin-left: 3%;
}

.teamD {
display: flex;
justify-content: space-between;
flex-flow: row nowrap;
}

.teamTitle {
font-family: "poppins";
color: black;
font-size: 50px;
font-weight: bolder;
margin-left: 3%;
margin-bottom: 20px;
}

.guy1 {
  background-color: #ffffff67;
  padding: 1.5%;
  border-color: rgb(221, 221, 221);
  border-style: solid;
  border-width: 1.5px;
  transition: transform .3s;

}

.guy1:hover {
  background-color: #ee314c;
  -ms-transform: scale(0.8); /* IE 9 */
  -webkit-transform: scale(0.8); /* Safari 3-8 */
  transform: scale(1.1);
  border: none;
  color: #ffffff;

} 

.guy2 {
  background-color: #ffffff67;
  padding: 1.5%;
  border-color: rgb(221, 221, 221);
  border-style: solid;
  border-width: 1.5px;
  transition: transform .3s;

}

.guy2:hover {
  background-color: #ee314c;
  -ms-transform: scale(0.8); /* IE 9 */
  -webkit-transform: scale(0.8); /* Safari 3-8 */
  transform: scale(1.1);
  border: none;
  color: #ffffff;

} 

.guy3 {
  background-color: #ffffff67;
  padding: 1.5%;
  border-color: rgb(221, 221, 221);
  border-style: solid;
  border-width: 1.5px;  
  transition: transform .3s;

}

.guy3:hover {
  background-color: #ee314c;
  -ms-transform: scale(0.8); /* IE 9 */
  -webkit-transform: scale(0.8); /* Safari 3-8 */
  transform: scale(1.1);
  border: none;
  color: #ffffff;

} 

.guy4 {
  background-color: #ffffff67;
  padding: 1.5%;
  border-color: rgb(221, 221, 221);
  border-style: solid;
  border-width: 1.5px;
  transition: transform .3s;
}

.guy4:hover {
  background-color: #ee314c;
  -ms-transform: scale(0.8); /* IE 9 */
  -webkit-transform: scale(0.8); /* Safari 3-8 */
  transform: scale(1.1);
  border: none;
  color: #ffffff;

} 

.guy5 {
  background-color: #ffffff67;
  padding: 1.5%;
  border-color: rgb(221, 221, 221);
  border-style: solid;
  border-width: 1.5px;
  transition: transform .3s;
}

.guy5:hover {
  background-color: #ee314c;
  -ms-transform: scale(0.8); /* IE 9 */
  -webkit-transform: scale(0.8); /* Safari 3-8 */
  transform: scale(1.1);
  border: none;
  color: #ffffff;
} 

.man1 {
  background-image: url(./logo/1.png);
  width: 125px;
  height: 125px;
  background-size: cover;
}

.man2 {
  background-image: url(./logo/2.png);
  width: 125px;
  height: 125px;
  background-size: cover;
}

.man7 {
  background-image: url(./logo/newMem.jpg);
  width: 125px;
  height: 125px;
  background-size: cover;
}

.man3 {
  background-image: url(./logo/3.png);
  width: 125px;
  height: 125px;
  background-size: cover;
}

.man4 {
  background-image: url(./logo/5.png);
  width: 125px;
  height: 125px;
  background-size: cover;
}

.man5 {
  background-image: url(./logo/6.png);
  width: 125px;
  height: 125px;
  background-size: cover;
}

.man6 {
  background-image: url(./logo/26.png);
  width: 125px;
  height: 125px;
  background-size: cover;
}

.name1 {
  margin-top: 10%;
  text-align: left;
  font-size: 15px;
  font-weight: bold;
}

.name2 {
  text-align: left;
  font-size: smaller;
}


/*.............. SOCIAL ICONS ..............*/

.socialContainer {
  width: 25%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  margin-top: 10px;
}

/*.............. FOOTER ..............*/

.footer {
  margin-top: 5%;
  margin-left: 3%;
}

.footerHeader {
  color: black;
  font-family: "Poppins";
  font-size: 50px;
  font-weight: bolder;
}

.footerP {
  color: rgb(75, 75, 75);
  font-size: 15px;
  width: 50%;
  margin-top: 20px;
}

.line {
  width: 50%;
  height: 1px;
  background-color: rgb(219, 219, 219);
  margin-top: 15px;
}

.footerBox1 {
  width: 300px;
  height: 100px;
  border-radius: 5px;
  margin-top: 25px;
  background-image: url(./assets/email_before.jpg);
  background-size: cover;
  border: none;
}

.footerBox1:hover { 
  background-image: url(./assets/email_after.jpg);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  color: rgb(226, 226, 226);
  cursor: pointer;

}

.footerBox2 {
  width: 300px;
  height: 100px;
  border-radius: 5px;
  margin-top: 25px;
  background-image: url(./assets/instagram_before.jpg);
  background-size: cover;
  margin-left: 15px;
  border: none;
}

.footerBox2:hover { 
  background-image: url(./assets/instagram_after.jpg);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  color: rgb(226, 226, 226);
  cursor: pointer;

}

.footerBox3 {
  width: 300px;
  height: 100px;
  border-radius: 5px;
  margin-top: 25px;
  background-image: url(./assets/twitter_before.jpg);
  background-size: cover;
  border: none;
}

.footerBox3:hover { 
  background-image: url(./assets/twitter_after.jpg);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  color: rgb(226, 226, 226);
  cursor: pointer;

}

.footerBox4 {
  width: 300px;
  height: 100px;
  border-radius: 5px;
  margin-top: 25px;
  background-image: url(./assets/discord_before.jpg);
  background-size: cover;
  margin-left: 15px;
  border: none;
}

.footerBox4:hover { 
  background-image: url(./assets/discord_after.jpg);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  color: rgb(226, 226, 226);
  cursor: pointer;

}

.iconsSection1 {
  display: flex;
  flex-flow: row nowrap;
}

.iconsSection2 {
  display: flex;
  flex-flow: row nowrap;
}

.footerDetails {
  color: black;
  font-family: "lucida sans unicode";
  font-size: 18px;
  font-weight: bolder;
  width: 300px;
  height: 100px;
  text-align: center;
  padding-left: 50px;
  padding-top: 30px;


}

.footerDetails2 {
  color: rgb(75, 75, 75);
  font-size: 12px;

}

.footerDetails:hover {
  color: rgb(226, 226, 226);
}

.footerDetails p{

 font-size: 9px ;
 color: rgb(161, 161, 161);
 margin-top: -3px;


}

.footerDetails2:hover {
  color: rgb(201, 201, 201);
}

}